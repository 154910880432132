import { withIronSession } from 'next-iron-session';
// import ApiClient from '../client';
import cookie from 'cookie';

export default function(handler) {
  const newHandler = async (...args) => {
    const handlerType = args[0] && args[1] ? 'api' : 'ssr';
    const req = handlerType === 'api' ? args[0] : args[0].req;
    const res = handlerType === 'api' ? args[1] : args[0].res;

    if (req) {
      // Server side
      req.user = req.session.get('user');
      req.token = req.session.get('token');

      const cookies = cookie.parse(req.headers.cookie || '');
      if (cookies && cookies['comet-userid']) {
        req.userId = cookies['comet-userid'];
      }
    }

    return handler(...args);
  }

  let domain;
  if (process.env.NODE_ENV === 'production') {
    // we are running on a deployed site
    if (process.env.IS_WITHCOMET_DEV) {
      // we are running on withcomet.dev
      domain = 'withcomet.dev';
    } else {
      // we are running on withcomet.com
      domain = 'withcomet.com';
    }
  } else {
    // we are running locally
    domain = 'localhost';
  }

  const ironSession = withIronSession(newHandler, {
    password: process.env.SECRET_COOKIE_PASSWORD || 'asdfasdfasdfasdfasdfasdfasdfasdf',
    cookieName: 'comet-app-cookie',
    cookieOptions: {
      // the next line allows to use the session in non-https environments like
      // Next.js dev mode (http://localhost:3000)
      secure: process.env.NODE_ENV === 'production' ? true : false,
      domain,
    },
  });

  return (...args) => {
    const handlerType = args[0] && args[1] ? 'api' : 'ssr';
    const req = handlerType === 'api' ? args[0] : args[0].req;
    const res = handlerType === 'api' ? args[1] : args[0].res;

    if (!req || !res) {
      return newHandler(...args);
    } else {
      return ironSession(...args);
    }
  }
};


import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';

const REGION = 'us-west-2';

const cognito = new CognitoIdentityClient({
  region: 'us-west-2',
  endpoint: 'https://cognito.withcomet.com/',
});

export default cognito;

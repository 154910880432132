
import request from 'axios';
import qs from 'query-string';
import https from 'https';
import getEnv from './getEnv';
import { clearUserId } from './userId';

// server-side proxy to h9t-api

class ApiClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl === undefined ? '/api/proxy' : baseUrl;
    // console.log(this.baseUrl)

    const methods = ['get', 'post', 'put', 'patch', 'delete'];
    for (let i = 0; i < methods.length; i += 1) {
      this[methods[i]] = async (
        url,
        config,
        auth = true,
        headers = {},
        userId = null,
        timeout = 10000,
      ) => {
        const setHeaders = { ... headers };

        if (config) {
          if (config.constructor.name === 'FormData') {
            // setHeaders['content-type'] = 'multipart/form-data';
          } else if (config.constructor.name === 'Object') {
            setHeaders['content-type'] = 'application/json';
          }
        }

        // console.log(getEnv())

        setHeaders['x-comet-environment'] = getEnv(baseUrl);

        if (userId) {
          setHeaders['x-comet-userid'] = userId;
        }
        // if (typeof window !== 'undefined') setHeaders['origin'] = window.location.origin;

        try {
          const response = await request({
            method: methods[i],
            url: this.baseUrl + url + ((methods[i] === 'get' && !url.includes('?')) ? ('?' + qs.stringify(config)) : ''),
            data: config,
            headers: setHeaders,
            withCredentials: !!auth,
            timeout,
          });

          return response.data;
        } catch (e) {
          if (e.response && e.response.status === 401) {
            if (typeof window !== 'undefined') {
              clearUserId();
            }
          }
          throw e;
        }
      }
    }
  }
}

export default ApiClient;

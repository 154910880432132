
import React, { useEffect, useState } from 'react'
import ApiClient from '../../lib/client'
import getEnv from '../../lib/getEnv'
import withAuth from './withAuth'
import io from 'socket.io-client'
import TokenClient from '../../lib/tokenClient'


const ChatContext = React.createContext(null)

const isProd = getEnv() === 'prod';
const baseUrl = isProd ? 'https://chat.withcomet.com' : 'https://chat-dev.withcomet.com'
// const baseUrl = 'http://localhost:3003'

function ChatContextProvider(props) {
  const [socket, setSocket] = useState(io(baseUrl, {
      'transports': ['websocket']
    })
  )

  const [open, setOpen] = useState(false)

  const [accessTokenStr, setAccessTokenStr] = useState(null)
  const [generateRequestOut, setGenerateRequestOut] = useState(false)

  const [activeRoom, setActiveRoom] = useState({})
  const [activeRoomMessages, setActiveRoomMessages] = useState([])
  const [roomMessages, setRoomMessages] = useState({})
  const [rooms, setRooms] = useState([])
  const [targetRoomId, setTargetRoomId] = useState(null);

  // const [typing, setTyping] = useState([])
  // const [events, setEvents] = useState([])

  // Mutate the state to switch the active room and set room[arrivedRoomIdx].unread to false
  const switchRoomById = (targetId) => {
    let targetRoom = {}
    let activeRoomMessages = []

    if (targetId) {
      setTargetRoomId(targetId);
      const roomIdx = rooms.findIndex((room) => {
        return room.id === targetId
      })
      if (roomIdx > -1) {
        let newRooms = [...rooms]
        newRooms[roomIdx].unread = false
        setRooms(newRooms)
        activeRoomMessages = roomMessages[targetId] ? roomMessages[targetId] : []
        targetRoom = rooms[roomIdx]
        setTargetRoomId(null);
      }
    }

    setActiveRoom(targetRoom)
    setActiveRoomMessages(activeRoomMessages)
  }

  useEffect(() => {
    if (rooms && targetRoomId) {
      switchRoomById(targetRoomId);
    }
  }, [rooms, targetRoomId])

  // Mutate the state to add message to the appropriate room
  const insertMessage = (message) => {
    let newRoomMessages = {...roomMessages}

    let inserted = false

    let messageCount = newRoomMessages[message.CometChatRoomId].length

    if (messageCount === 0) {
      // Insert into empty store.
      newRoomMessages[message.CometChatRoomId].push(message)
      setRoomMessages(newRoomMessages)
      return
    }

    if (messageCount === 1) {
      // Insert second message
      if (message.timestamp > newRoomMessages[message.CometChatRoomId][0].time) {
        newRoomMessages[message.CometChatRoomId].push(message)
        setRoomMessages(newRoomMessages)
        return
      } else {
        newRoomMessages[message.CometChatRoomId].unshift(message)
        setRoomMessages(newRoomMessages)
        return
      }
    }

    let lastMessage = newRoomMessages[message.CometChatRoomId][messageCount - 1]

    if (lastMessage.time < message.timestamp) {
      // Optimize adding new latest message
      newRoomMessages[message.CometChatRoomId].push(message)
      setRoomMessages(newRoomMessages)
      return
    }

    let firstMessage = newRoomMessages[message.CometChatRoomId][0]
    if (firstMessage.time > message.timestamp) {
      // Optimize adding new oldest message
      newRoomMessages[message.CometChatRoomId].unshift(message)
      setRoomMessages(newRoomMessages)
      return
    }

    // Fallthrough for insert in the middle of the set.
    // console.log('middle insert?')
    for (
      let i = 0;
      i < newRoomMessages[message.CometChatRoomId].length - 1;
      i++
    ) {
      if (
        newRoomMessages[message.CometChatRoomId][i].time >= message.timestamp &&
        newRoomMessages[message.CometChatRoomId][i + 1].time <= message.timestamp
      ) {
        newRoomMessages[message.CometChatRoomId].splice(i, 0, message)
        inserted = true
        break
      }
    }

    if (!inserted) {
      newRoomMessages[message.CometChatRoomId].unshift(message)
    }

    setRoomMessages(newRoomMessages)
  }

  const moveRoomToTop = (roomId, lastMessageAt) => {
    const roomIdx = rooms.findIndex((room) => room.id === roomId)
    if (roomIdx > -1) {
      let newRooms = [...rooms]
      newRooms.unshift(newRooms.splice(roomIdx, 1)[0])
      newRooms[0].lastMessageAt = lastMessageAt
      setRooms(newRooms)
    }
  }

  // Invoke `insertMessage` and set room[arrivedRoomIdx].unread to true
  const liveMessage = (message) => {
    insertMessage(message)
    moveRoomToTop(message.CometChatRoomId, message.createdAt)

    if (message.CometChatRoomId !== activeRoom.id) {
      // Message arrive in another room, so need to trigger the dot on that room
      let arrivedRoomIdx = rooms.findIndex(function (room) {
        return room.id === message.CometChatRoomId
      })
      let newRooms = [...rooms]
      newRooms[arrivedRoomIdx].unread = true
      setRooms(newRooms)
    }
    // Message is for active room, so execute setActiveRoomMessages
    else {
      setActiveRoomMessages(roomMessages[activeRoom.id])
    }
  }

  const sendMessage = (text) => {

    const trimmedText = text.trim()
    if (!trimmedText) return

    socket.emit('new message', {
        roomId: activeRoom.id,
        text: trimmedText,
        accessTokenStr,
        user: props.user,
      },
      function (error, message) {
        if (error) {
          return console.error(error)
        }
        // no need for room switching because you're already in a room
        insertMessage(message)
        moveRoomToTop(message.CometChatRoomId, message.createdAt)
        // removeTyper({
        //   roomId: self.state.activeRoomId,
        //   CometUser: self.state.CometUser,
        // });
        // lastTyping = 0;
      }
    )
  }

  const openRoomWithUser = (toUser) => {
    const roomName = `@${props.user.username} and @${toUser.username}`
    socket.emit('new room', {
        accessTokenStr,
        roomName,
        fromUserId: props.user.id,
        toUserId: toUser.id,
      },
      (error, room, isNewRoom) => {
        if (error) {
          return console.error(error)
        }
        // if is new room, create add new room to `rooms` and empty message array
        if (isNewRoom) {
          let newRooms = [...rooms]
          newRooms.unshift(room)
          setRooms(newRooms)

          let newRoomMessages = {...roomMessages}
          newRoomMessages[room.id] = []
          setRoomMessages(newRoomMessages)

          setActiveRoom(room)
          return
        }

        switchRoomById(room.id)
      }
    );
  }


  useEffect(() => {
    (async () => {
      if (props.user && props.user.loggedIn && !generateRequestOut) {
        // POST /comet/auth/generate
        const tokenClient = new TokenClient(new ApiClient());
        setGenerateRequestOut(true);
        await tokenClient.init(props.user.id);
        setGenerateRequestOut(false);
        const token = tokenClient.getToken();
        setAccessTokenStr(token.string);

        socket.emit('authenticate user', {
            userId: props.user.id,
            accessTokenStr: token.string,
          },
          (error, response=null) => {
            if (error) {
              console.log(error);
              return;
            }
            console.log('authenticated to chat server');

            socket.emit('room list', {
                accessTokenStr: token.string,
                userId: props.user.id
              },
              (error, roomList, roomMessages) => {
                if (error) {
                  console.error(error)
                  return
                }
                setRoomMessages(roomMessages)
                const newRooms = rooms.concat(roomList)
                setRooms(newRooms)
              }
            );
          }
        );
      }
    })();

    return () => {
      if (socket) socket.close();
      setSocket(null);
    };
  }, [(props.user || {}).id])

  useEffect(() => {
    socket.on('new room', (room, toUserId) => {
      if (toUserId == props.user.id) {
        let newRooms = [...rooms]
        newRooms.push(room)
        setRooms(newRooms)

        let newRoomMessages = {...roomMessages}
        newRoomMessages[room.id] = []
        setRoomMessages(newRoomMessages)
      }
    });
    socket.on('new message', (message) => {
      if (rooms.find(room => room.id == message.CometChatRoomId) && roomMessages[message.CometChatRoomId]) {
        liveMessage(message)
      }

    });

    return () => {
      socket.off('new room');
      socket.off('new message');
    }
  }, [rooms, roomMessages, activeRoom, activeRoomMessages])

  useEffect(() => {
    setActiveRoomMessages(roomMessages[activeRoom.id] || [])
  }, [activeRoom])

  let value = {
    socket,
    open,
    setOpen,
    accessTokenStr,
    activeRoom,
    activeRoomMessages,
    roomMessages,
    rooms,
    switchRoomById,
    insertMessage,
    liveMessage,
    sendMessage,
    openRoomWithUser,
  }

  return (
    <ChatContext.Provider value={value}>
      {props.children}
    </ChatContext.Provider>
  )
}

export {ChatContext}

export default withAuth(ChatContextProvider, { authRequired: false })








// // TODO: unimplemented stuff -- typing and online presence indicators
// let store = {
//   data: {
//     state: {
//       CometUser: null,
//       authenticated: false,
//       avatar: null,
//       // rooms may have identical names; must differentiate based on id
//       activeRoomId: null,
//       lastTyping: Date.now(),
//       belowMessagesView: 'message-input',
//       presentCount: 0,
//     },
//   },

//   // Helper function that runs on a schedule and expires any typers that
//   // have not emitted a typing event recently.
//   _expireTypersInterval: null,
//   _expireTypers: function () {
//     var now = Date.now()

//     for (var i = 0; i < this.data.typing.length; i++) {
//       if (this.data.typing[i].until < now) {
//         this.data.typing.splice(i, 1)
//       }
//     }

//     if (this.data.typing.length === 0) {
//       // No typers left so stop checking for then.
//       clearInterval(this._expireTypersInterval)
//       this._expireTypersInterval = null
//     }
//   },

//   // Mutate the data store to add a typing user.
//   addTyper: function (typer) {
//     // Add an expiration.
//     typer.until = Date.now() + 3000

//     // Prevent dupes and the splice + push method triggers Vue update.
//     this.removeTyper(typer)
//     this.data.typing.push(typer)

//     if (!this._expireTypersInterval) {
//       // Now that we have typers, start checking every 500ms to see if we need to expire one or more.
//       this._expireTypersInterval = setInterval(
//         this._expireTypers.bind(this),
//         500
//       )
//     }
//   },

//   removeTyper: function (typer) {
//     for (var i = 0; i < this.data.typing.length; i++) {
//       if (
//         this.data.typing[i].username === typer.username &&
//         this.data.typing[i].room === typer.room
//       ) {
//         this.data.typing.splice(i, 1)
//         break
//       }
//     }
//   },

//   // Helper function that runs on a schedule and expires any typers that
//   // have not emitted a typing event recently.
//   _expireEventsInterval: null,
//   _expireEvents: function () {
//     var now = Date.now()

//     for (var i = 0; i < this.data.events.length; i++) {
//       if (this.data.events[i].until < now) {
//         this.data.events.splice(i, 1)
//       }
//     }

//     if (this.data.events.length === 0) {
//       // No typers left so stop checking for then.
//       clearInterval(this._expireEventsInterval)
//       this._expireEventsInterval = null
//     }
//   },

//   addEvent: function (event) {
//     event.until = Date.now() + 3000
//     this.data.events.push(event)

//     if (!this._expireEventsInterval) {
//       // Now that we have events, start checking every 500ms to see if we need to expire one or more.
//       this._expireEventsInterval = setInterval(
//         this._expireEvents.bind(this),
//         500
//       )
//     }
//   },
// }

import API from './client';

const api = new API('');

export default async function fetcher(resource, options = {}) {
  // try {
  let method = options.method?.toLowerCase() || 'get';

  const result = await api[method](resource, options.data, true, options.headers);

  // console.log(result);
  return result;

  // } catch (e) {
  //   //
  // }
  // try {
  //   const setHeaders = { ...options.headers };

  //   if (
  //     typeof window !== 'undefined'
  //     && (
  //       window.location.hostname.includes('localhost')
  //       || window.location.hostname.includes('ngrok.io')
  //       || window.location.hostname.includes('lvh.me')
  //     )
  //   ) {
  //     // local env
  //     setHeaders['x-comet-environment'] = 'local';
  //   }

  //   const response = await fetch(resource, { ...options, headers: setHeaders });

  //   // if the server replies, there's always some data in json
  //   // if there's a network error, it will throw at the previous line
  //   const data = await response.json()

  //   if (response.ok) {
  //     return data
  //   }

  //   const error = new Error(response.statusText)
  //   error.response = response
  //   error.data = data
  //   throw error
  // } catch (error) {
  //   if (!error.data) {
  //     error.data = { message: error.message }
  //   }
  //   throw error
  // }
}


export default (hostname) => {
  let result = null;

  if (!hostname && typeof window !== 'undefined') {
    hostname = window.location.hostname
  } else {
    if (process.env.H9T_ENV === 'production') {
      return 'prod';
    } else if (process.env.H9T_ENV === 'development') {
      return 'dev';
    }
  }

  if (hostname) {
    if (
      (
        hostname.includes('localhost')
        || hostname.includes('ngrok.io')
        || hostname.includes('lvh.me')
      )
    ) {
      // local env
      result = 'local';
    }

    if (
      (
        hostname.includes('withcomet.dev')
        || hostname.includes('vercel.app')
      )
    ) {
      result = 'dev';
    }

    if (
      (
        hostname.includes('withcomet.com')
      )
    ) {
      result = 'prod';
    }
  }

  return result;
}

import React, { useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';
import { SWRConfig } from 'swr';
import 'tailwindcss/tailwind.css';
import fetcher from '../lib/fetcher';
import { useRouter } from 'next/router';
import * as ga from '../lib/ga';
import Head from 'next/head';
import SnowballProvider from '../components/data/snowballWrapper';
import qs from 'query-string';
import ChatContextProvider from '../components/data/chatWrapper';

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const routes = router.route.split('/')
  const defaultTitle = routes[routes.length - 1]

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  useEffect(() => {
    const handleRouteChange = (url) => {
      // ga.pageview(url)
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  let chainType, chainId;

  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  if (isClient) {
    const query = qs.parse(window.location.search);
    chainType = query.chainType;
    chainId = query.chainId;
  }

  return (
    <>
      <Head>
        <title>Comet</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <SWRConfig
        value={{
          fetcher,
          onError: (err) => {
            console.error(err);
          }
        }}
      >
        <SnowballProvider chainType={chainType} chainId={parseInt(chainId)}>
          <ChatContextProvider>
            <Component {...pageProps} />
          </ChatContextProvider>
        </SnowballProvider>
      </SWRConfig>
      <Analytics
        beforeSend={(event) => {
          // Don't send events for Stripe redirect pages
          if (event.url.includes('/stripe')) {
            return null;
          }
        }}
      />
    </>
  )
}

export default MyApp

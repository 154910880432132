
import React, { useEffect } from 'react';
import withSession from '../../lib/middleware/session';
import { useRouter } from 'next/router';
import useUser from '../../lib/data/useUser';
import useIdentities from '../../lib/data/useIdentities';
import { clearUserId, getUserId, setUserId } from '../../lib/userId';
import ApiClient from '../../lib/client';

export default (WrappedComponent, options = {}) => {
  const HOC = (props) => {
    const {
      authRequired = true,
    } = options;

    const { user, mutateUser } = useUser();
    // const { identities, mutateIdentities } = useIdentities();

    const account = {
      ...props.user,
      ...user,
    };

    const Router = useRouter();

    const fullPath = Router.asPath;

    useEffect(() => {
      const api = new ApiClient();

      if (account && account.loggedIn && !account.stripeSeller?.exists) {
        api.post('/stripe/seller').then(() => { mutateUser(); });
      }

      if (authRequired && !account.loggedIn) {
        clearUserId();
        Router.replace(`/auth/login?from=${encodeURIComponent(fullPath)}`);
      }
    }, []);

    if (account && account.loggedIn && !account.id) {
      return null;
    }

    return <div><WrappedComponent {...props} user={account} mutateUser={mutateUser} /></div>;
  }

  HOC.getInitialProps = withSession(
    async ({ req, res, query }) => {
      let result = {
        ua: req ? req.headers['user-agent'] : navigator.userAgent,
      };

      if (req) {
        result = {
          ...result,
          user: req.user,
        };
      }

      // HOCs with getInitialProps need to call child's getInitialProps
      if (WrappedComponent.getInitialProps) {
        result = {
          ...result,
          ...(await WrappedComponent.getInitialProps({req, res, query})),
        };
      }

      return result;
    }
  );

  return HOC;
}

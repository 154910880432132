
import React, { useEffect, useState } from 'react';
import ApiClient from '../../lib/client';
import getEnv from '../../lib/getEnv';
import SnowballClient from '../../lib/snowballClient';
import withAuth from './withAuth';

const SnowballContext = React.createContext(null);

function SnowballProvider(props) {
  const [snowball, setSnowball] = useState(null);
  const [snowballRequestOut, setSnowballRequestOut] = useState(false);

  useEffect(() => {
      (async () => {
      if (props.user && props.user.loggedIn && !snowballRequestOut) {
        const env = getEnv();
        let chainId = props.chainId;
        if (!chainId) {
          chainId = 103;
          if (env === 'prod') {
            chainId = 101;
          }
        }

        let chainType = props.chainType;
        if (!chainType) {
          chainType = 'solana';
        }

        // Check if this user already has a verified chainType:chainId address
        // with a snowball. If not, init + verify snowball; otherwise just init
        const { addresses } = props.user;
        let shouldVerify = true;
        for (let i = 0; i < addresses.length; i += 1) {
          if (
            addresses[i].chainType === chainType
            && addresses[i].chainId === chainId
            && addresses[i].hasSnowball
          ) {
            shouldVerify = false;
          }
        }

        const snowballClient = new SnowballClient(new ApiClient());

        setSnowballRequestOut(true);

        await snowballClient.init(chainType, chainId, shouldVerify);

        setSnowball(snowballClient);
        setSnowballRequestOut(false);
      } else {
        setSnowball(null);
      }
    })();
  }, [(props.user || {}).id]);

  return (
    <SnowballContext.Provider value={snowball}>
      {props.children}
    </SnowballContext.Provider>
  )
}

export {SnowballContext};

export default withAuth(SnowballProvider, { authRequired: false });

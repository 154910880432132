
import Cookies from 'js-cookie';
import getEnv from './getEnv';

const getDomain = () => {
  const env = getEnv();

  if (env === 'local') {
    return 'localhost';
  } else if (env === 'dev') {
    return 'withcomet.dev';
  } else {
    return 'withcomet.com';
  }
}

export const setUserId = (userId) => {
  if (userId) {
    Cookies.set('comet-userid', userId, { domain: getDomain() });
  }
}

export const clearUserId = () => {
  Cookies.remove('comet-userid', { domain: getDomain() });
}

export const getUserId = () => {
  return Cookies.get('comet-userid');
}

import moment from 'moment';

class TokenClient {
  constructor(api) {
    this.api = api;
    this.userId = null;
    this.interval = setInterval(() => {
      if (this.token && this.token.expires && moment(this.token.expires).add(5, 'second') < moment()) {
        // generate new token
        this.token = this.generateToken(this.userId);
      }
    }, 10000);
  }

  async generateToken(userId) {
    const currentToken = JSON.parse(localStorage.getItem('comet-web-token'));

    if (currentToken && currentToken.string && moment(currentToken.expires) > moment()) {
      return currentToken;
    }

    localStorage.clear();

    const newToken = await this.api.post(
      '/auth/generate',
      {
        user: userId
      },
      true,
      {
        'cache-control': 'no-cache',
      },
    );

    localStorage.setItem('comet-web-token', JSON.stringify(newToken));

    return newToken;
  }

  // Does the following:
  // 1. Generates access token
  async init(userId) {
    this.userId = userId;
    this.token = await this.generateToken(userId);
  }

  getToken() {
    return this.token;
  }
};

export default TokenClient;


import { GetCredentialsForIdentityCommand } from '@aws-sdk/client-cognito-identity';
import { DecryptCommand, EncryptCommand, KMSClient } from '@aws-sdk/client-kms';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

import Web3 from 'web3';
import * as anchor from '@project-serum/anchor'
import bs58 from 'bs58';

import cognito from './aws/cognito';
import nacl from 'tweetnacl';
import * as secp from '@noble/secp256k1';
import sha256 from 'crypto-js/sha256';
import {
  hashPersonalMessage,
  ecsign,
  toRpcSig,
} from 'ethereumjs-util';
import { getMessage } from 'eip-712';
const cometTxForwarderAbi = require('./abi/cometTxForwarder').default;
const secp256k1 = require('secp256k1/elliptic');
import { ethers } from 'ethers';

// import { ecsign } from 'ethereumjs-util';

class SnowballClient {
  constructor(api) {
    this.api = api;
  }

  async getCognitoIdentity() {
    return this.api.get(
      '/auth/snowball/identity',
      {},
      true,
      {
        'cache-control': 'no-cache',
      },
    );
  }

  async setKMSAuth(identityId, identityToken) {
    const command = new GetCredentialsForIdentityCommand({
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': identityToken,
      },
    });

    const { Credentials } = await cognito.send(command);

    this.kms = new KMSClient({
      region: 'us-west-2',
      credentials: {
        secretAccessKey: Credentials.SecretKey,
        accessKeyId: Credentials.AccessKeyId,
        sessionToken: Credentials.SessionToken,
        expiration: Credentials.Expiration,
      },
    });
  }

  async encrypt(s) {
    const encryptionContext = {
      userId: this.userId,
    };

    const command = new EncryptCommand({
      KeyId: this.keyId,
      EncryptionContext: encryptionContext,
      Plaintext: Uint8Array.from(Buffer.from(s, 'utf-8')),
    });

    const result = await this.kms.send(command);
    return Buffer.from(result.CiphertextBlob).toString('hex');
  }

  async decrypt(s) {
    const encryptionContext = {
      userId: this.userId,
    };

    const command = new DecryptCommand({
      KeyId: this.keyId,
      EncryptionContext: encryptionContext,
      CiphertextBlob: Uint8Array.from(Buffer.from(s, 'hex')),
    });

    const result = await this.kms.send(command);
    return Buffer.from(result.Plaintext).toString('utf-8');
  };

  generateWallet(chainType = 'evm') {
    if (chainType === 'evm') {
      const web3 = new Web3();
      const account = web3.eth.accounts.create(`${(new Date()).getTime()}`);

      return {
        address: account.address,
        privateKey: account.privateKey,
      };
    } else if (chainType === 'solana') {
      const keypair = anchor.web3.Keypair.generate();

      return {
        address: keypair.publicKey.toBase58(),
        privateKey: bs58.encode(keypair.secretKey),
      };
    } else {
      throw new Error('Invalid chain type');
    }
  }

  async getSnowballs() {
    // get all my snowballs
    const { snowballs } = await this.api.get('/auth/snowball');

    let evm, solana;
    for (let i = 0; i < snowballs.length; i += 1) {
      if (!evm && snowballs[i].chainType === 'evm') {
        evm = snowballs[i];
      }
      if (!solana && snowballs[i].chainType === 'solana') {
        solana = snowballs[i];
      }
    }

    return {
      evm,
      solana,
    }
  }

  async signMessage(msg) {
    if (!this.snowball) throw new Error('No snowball');

    if (this.snowball.chainType === 'evm') {
      const msgArray = Buffer.from(msg, 'utf-8');
      const hash = hashPersonalMessage(msgArray);
      const sig = ecsign(hash, Buffer.from(this.snowball.privateKey.replace('0x', ''), 'hex'));

      return toRpcSig(sig.v, sig.r, sig.s);
    } else if (this.snowball.chainType === 'solana') {
      // Solana uses standard ED25519 curve
      const signature = nacl.sign.detached(
        Uint8Array.from(Buffer.from(msg, 'utf-8')),
        Uint8Array.from(bs58.decode(this.snowball.privateKey)),
      );

      return bs58.encode(Buffer.from(signature));
    }
  }

  async signSolanaTransaction(tx) {
    if (!this.snowball) throw new Error('No snowball');

    if (this.snowball.chainType !== 'solana') {
      throw new Error('Not a Solana snowball');
    }

    const signature = nacl.sign.detached(
      tx.serializeMessage(),
      Uint8Array.from(bs58.decode(this.snowball.privateKey)),
    );

    tx.addSignature(new anchor.web3.PublicKey(this.snowball.address), signature);
  }

  async verifyAddress() {
    // first get a nonce from the server
    let pathComponent = this.snowball.chainType === 'evm' ? 'eth' : 'solana';

    const { nonce } = await this.api.post(`/auth/${pathComponent}/start`, { address: this.snowball.address });
    const signature = await this.signMessage(`${nonce}`);

    // once signature is made we can verify again on the server
    await this.api.post(`/auth/${pathComponent}/verify`, {
      address: this.snowball.address,
      chainId: this.snowball.chainId,
      signature,
    });
  }

  // Does the following:
  // 1. Gets Cognito identity
  // 2. Checks if there are any Snowballs (private keys) for the given chainType/chainId
  // 3. If there are, decrypt them and store them locally
  // 4. If not, generate a new key, encrypt it and store it
  // 5. If a new key was generated, make sure it's also verified on the backend
  async init(chainType, chainId, verify = true) {
    const { identityId, identityToken, keyId, userId } = await this.getCognitoIdentity();
    await this.setKMSAuth(identityId, identityToken);
    this.keyId = keyId;
    this.userId = userId;

    let snowballs = await this.getSnowballs();
    let privateKey, address;
    let createdSnowball = false;

    if (!snowballs[chainType]) {
      // generate one
      const newWallet = this.generateWallet(chainType);
      privateKey = newWallet.privateKey;
      address = newWallet.address;

      createdSnowball = true;

      // encrypt the private key
      const encryptedPrivateKey = await this.encrypt(privateKey);

      // persist everything
      await this.api.post('/auth/snowball', {
        encryptedPrivateKey,
        address,
        chainType,
        chainId,
        keyId,
      });
    } else {
      address = snowballs[chainType].address;
      privateKey = await this.decrypt(snowballs[chainType].encryptedPrivateKey);
    }

    this.snowball = {
      address,
      privateKey,
      chainType,
      chainId,
    };

    if (verify) await this.verifyAddress();
  }

  signTypedData(typedData) {
    const message = getMessage(typedData, true);

    const sk = new ethers.utils.SigningKey(this.snowball.privateKey);
    const signature = ethers.utils.joinSignature(sk.signDigest(message));
    return signature;
  }

  getAddress() {
    return this.snowball.address;
  }

  getChainId() {
    return this.snowball.chainId;
  }

  getChainType() {
    return this.snowball.chainType;
  }
};

export default SnowballClient;
